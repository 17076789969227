angular.module('gantt.templates', []).run(['$templateCache', function($templateCache) {
    $templateCache.put('template/gantt.tmpl.html',
        '<div class="gantt unselectable" ng-cloak gantt-scroll-manager gantt-element-width-listener="ganttElementWidth">\n' +
        '    <gantt-side>\n' +
        '        <gantt-side-background>\n' +
        '        </gantt-side-background>\n' +
        '        <gantt-side-content>\n' +
        '        </gantt-side-content>\n' +
        '        <div gantt-resizer="gantt.side.$element" gantt-resizer-event-topic="side" gantt-resizer-enabled="{{$parent.gantt.options.value(\'allowSideResizing\')}}" resizer-width="sideWidth" class="gantt-resizer">\n' +
        '            <div ng-show="$parent.gantt.options.value(\'allowSideResizing\')" class="gantt-resizer-display"></div>\n' +
        '        </div>\n' +
        '    </gantt-side>\n' +
        '    <gantt-scrollable-header>\n' +
        '        <gantt-header gantt-element-height-listener="$parent.ganttHeaderHeight">\n' +
        '            <gantt-header-columns>\n' +
        '                <div ng-repeat="header in gantt.columnsManager.visibleHeaders track by $index">\n' +
        '                    <div class="gantt-header-row" ng-class="{\'gantt-header-row-last\': $last, \'gantt-header-row-first\': $first}">\n' +
        '                        <gantt-column-header ng-repeat="column in header"></gantt-column-header>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '            </gantt-header-columns>\n' +
        '        </gantt-header>\n' +
        '    </gantt-scrollable-header>\n' +
        '    <gantt-scrollable>\n' +
        '        <gantt-body>\n' +
        '            <gantt-body-background>\n' +
        '                <gantt-row-background ng-repeat="row in gantt.rowsManager.visibleRows track by row.model.id"></gantt-row-background>\n' +
        '            </gantt-body-background>\n' +
        '            <gantt-body-foreground>\n' +
        '                <div class="gantt-current-date-line" ng-show="currentDate === \'line\' && gantt.currentDateManager.position >= 0 && gantt.currentDateManager.position <= gantt.width" ng-style="{\'left\': gantt.currentDateManager.position + \'px\' }"></div>\n' +
        '            </gantt-body-foreground>\n' +
        '            <gantt-body-columns>\n' +
        '                <gantt-column ng-repeat="column in gantt.columnsManager.visibleColumns">\n' +
        '                    <gantt-time-frame ng-repeat="timeFrame in column.visibleTimeFrames"></gantt-time-frame>\n' +
        '                </gantt-column>\n' +
        '            </gantt-body-columns>\n' +
        '            <div ng-if="gantt.columnsManager.visibleColumns == 0" style="background-color: #808080"></div>\n' +
        '            <gantt-body-rows>\n' +
        '                <gantt-timespan ng-repeat="timespan in gantt.timespansManager.timespans track by timespan.model.id"></gantt-timespan>\n' +
        '                <gantt-row ng-repeat="row in gantt.rowsManager.visibleRows track by row.model.id">\n' +
        '                    <gantt-task ng-repeat="task in row.visibleTasks track by task.model.id">\n' +
        '                    </gantt-task>\n' +
        '                </gantt-row>\n' +
        '            </gantt-body-rows>\n' +
        '        </gantt-body>\n' +
        '    </gantt-scrollable>\n' +
        '\n' +
        '    <!-- Plugins -->\n' +
        '    <ng-transclude></ng-transclude>\n' +
        '\n' +
        '    <!--\n' +
        '    ******* Inline templates *******\n' +
        '    You can specify your own templates by either changing the default ones below or by\n' +
        '    adding an attribute template-url="<url to your template>" on the specific element.\n' +
        '    -->\n' +
        '\n' +
        '    <!-- Body template -->\n' +
        '    <script type="text/ng-template" id="template/ganttBody.tmpl.html">\n' +
        '        <div ng-transclude class="gantt-body" ng-style="{\'width\': gantt.width > 0 ? gantt.width +\'px\' : undefined}"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Header template -->\n' +
        '    <script type="text/ng-template" id="template/ganttHeader.tmpl.html">\n' +
        '        <div ng-transclude class="gantt-header"\n' +
        '             ng-show="gantt.columnsManager.columns.length > 0 && gantt.columnsManager.headers.length > 0"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Side template -->\n' +
        '    <script type="text/ng-template" id="template/ganttSide.tmpl.html">\n' +
        '        <div ng-transclude class="gantt-side" style="width: auto;"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Side content template-->\n' +
        '    <script type="text/ng-template" id="template/ganttSideContent.tmpl.html">\n' +
        '        <div class="gantt-side-content">\n' +
        '        </div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Header columns template -->\n' +
        '    <script type="text/ng-template" id="template/ganttHeaderColumns.tmpl.html">\n' +
        '        <div ng-transclude class="gantt-header-columns"\n' +
        '              gantt-horizontal-scroll-receiver></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <script type="text/ng-template" id="template/ganttColumnHeader.tmpl.html">\n' +
        '        <div class="gantt-column-header" ng-class="{\'gantt-column-header-last\': $last, \'gantt-column-header-first\': $first}">{{::column.label}}</div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Body background template -->\n' +
        '    <script type="text/ng-template" id="template/ganttBodyBackground.tmpl.html">\n' +
        '        <div ng-transclude class="gantt-body-background"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Body foreground template -->\n' +
        '    <script type="text/ng-template" id="template/ganttBodyForeground.tmpl.html">\n' +
        '        <div ng-transclude class="gantt-body-foreground"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Body columns template -->\n' +
        '    <script type="text/ng-template" id="template/ganttBodyColumns.tmpl.html">\n' +
        '        <div ng-transclude class="gantt-body-columns"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <script type="text/ng-template" id="template/ganttColumn.tmpl.html">\n' +
        '        <div ng-transclude class="gantt-column gantt-foreground-col" ng-class="{\'gantt-column-last\': $last, \'gantt-column-first\': $first}"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <script type="text/ng-template" id="template/ganttTimeFrame.tmpl.html">\n' +
        '        <div class="gantt-timeframe"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Scrollable template -->\n' +
        '    <script type="text/ng-template" id="template/ganttScrollable.tmpl.html">\n' +
        '        <div ng-transclude class="gantt-scrollable" gantt-scroll-sender ng-style="getScrollableCss()"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <script type="text/ng-template" id="template/ganttScrollableHeader.tmpl.html">\n' +
        '        <div ng-transclude class="gantt-scrollable-header" ng-style="getScrollableHeaderCss()"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Rows template -->\n' +
        '    <script type="text/ng-template" id="template/ganttBodyRows.tmpl.html">\n' +
        '        <div ng-transclude class="gantt-body-rows"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Timespan template -->\n' +
        '    <script type="text/ng-template" id="template/ganttTimespan.tmpl.html">\n' +
        '        <div class="gantt-timespan" ng-class="timespan.model.classes">\n' +
        '        </div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Task template -->\n' +
        '    <script type="text/ng-template" id="template/ganttTask.tmpl.html">\n' +
        '        <div class="gantt-task" ng-class="task.model.classes">\n' +
        '            <gantt-task-background></gantt-task-background>\n' +
        '            <gantt-task-foreground></gantt-task-foreground>\n' +
        '            <gantt-task-content></gantt-task-content>\n' +
        '        </div>\n' +
        '    </script>\n' +
        '\n' +
        '    <script type="text/ng-template" id="template/ganttTaskBackground.tmpl.html">\n' +
        '        <div class="gantt-task-background" ng-style="{\'background-color\': task.model.color}"></div>\n' +
        '    </script>\n' +
        '\n' +
        '    <script type="text/ng-template" id="template/ganttTaskForeground.tmpl.html">\n' +
        '        <div class="gantt-task-foreground">\n' +
        '            <div ng-if="task.truncatedRight" class="gantt-task-truncated-right">&gt;</div>\n' +
        '            <div ng-if="task.truncatedLeft" class="gantt-task-truncated-left">&lt;</div>\n' +
        '        </div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Task content template -->\n' +
        '    <script type="text/ng-template" id="template/ganttTaskContent.tmpl.html">\n' +
        '        <div class="gantt-task-content" unselectable="on"><span unselectable="on" gantt-bind-compile-html="getTaskContent()"/></div>\n' +
        '    </script>\n' +
        '\n' +
        '\n' +
        '    <!-- Row background template -->\n' +
        '    <script type="text/ng-template" id="template/ganttRowBackground.tmpl.html">\n' +
        '        <div class="gantt-row gantt-row-height"\n' +
        '             ng-class="row.model.classes"\n' +
        '             ng-class-odd="\'gantt-row-odd\'"\n' +
        '             ng-class-even="\'gantt-row-even\'"\n' +
        '             ng-style="{\'height\': row.model.height}">\n' +
        '            <div class="gantt-row-background"\n' +
        '                 ng-style="{\'background-color\': row.model.color}">\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Row template -->\n' +
        '    <script type="text/ng-template" id="template/ganttRow.tmpl.html">\n' +
        '        <div class="gantt-row gantt-row-height"\n' +
        '             ng-class="row.model.classes"\n' +
        '             ng-class-odd="\'gantt-row-odd\'"\n' +
        '             ng-class-even="\'gantt-row-even\'"\n' +
        '             ng-style="{\'height\': row.model.height}">\n' +
        '            <div ng-transclude class="gantt-row-content"></div>\n' +
        '        </div>\n' +
        '    </script>\n' +
        '\n' +
        '    <!-- Side background template -->\n' +
        '    <script type="text/ng-template" id="template/ganttSideBackground.tmpl.html">\n' +
        '        <div class="gantt-side-background">\n' +
        '            <div class="gantt-side-background-header" ng-style="{height: $parent.ganttHeaderHeight + \'px\'}">\n' +
        '                <div ng-show="$parent.ganttHeaderHeight" class="gantt-header-row gantt-side-header-row"></div>\n' +
        '            </div>\n' +
        '            <div class="gantt-side-background-body" ng-style="getMaxHeightCss()">\n' +
        '                <div gantt-vertical-scroll-receiver>\n' +
        '                    <div class="gantt-row gantt-row-height "\n' +
        '                         ng-class-odd="\'gantt-row-odd\'"\n' +
        '                         ng-class-even="\'gantt-row-even\'"\n' +
        '                         ng-class="row.model.classes"\n' +
        '                         ng-repeat="row in gantt.rowsManager.visibleRows track by row.model.id"\n' +
        '                         ng-style="{\'height\': row.model.height}">\n' +
        '                        <div gantt-row-label class="gantt-row-label gantt-row-background"\n' +
        '                             ng-style="{\'background-color\': row.model.color}">\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </script>\n' +
        '</div>\n' +
        '');
}]);
